<template>
  <div>
    <vs-popup class="sm:popup-w-70" title="Tambah STC" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full.5">
            <label class="ml-1 text-xs">No. Kontrak *</label>
            <div @click="modalKontrak.active = true" >
              <vx-input-group>
                <vs-input placeholder="Pilih Kontrak" :value="data.no_kontrak" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. Unit</label>
            <vs-input class="w-full" v-model="data.no_unit" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Nama Customer</label>
            <vs-input class="w-full" v-model="data.nama_customer" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. STC *</label>
            <flat-pickr class="w-full" v-model="data.tgl_stc"></flat-pickr>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. AJB</label>
            <vs-select class="w-full" v-model="data.id_progress_dokumen_unit" :disabled="data.listAjb.length < 1" @change="onChangeNoAjb">
              <vs-select-item v-for="(it, index) in data.listAjb" :key="index" :value="it.id" :text="it.no_dokumen"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. AJB</label>
            <vs-input class="w-full" v-model="data.tgl_ajb" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. Garansi *</label>
            <vs-input class="w-full" v-model="data.no_garansi"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Masa Garansi (hari) *</label>
            <vs-input class="w-full" type="number" :value="data.masa_garansi" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. Habis Garansi *</label>
            <vs-input class="w-full" :value="tglHabisGaransi" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Upload Foto * <span class="text-xs" v-if="isCompressingFoto">(compressing...)</span></label>
            <vs-input class="w-full" type="file" accept="image/*" multiple v-model="data.fotoTemp" @change="onSelectedPhotos"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Upload Files</label>
            <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" multiple v-model="data.filesTemp" @change="onSelectedFiles"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal kontrak-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Kontrak"
                :active.sync="modalKontrak.active">
        <Kontrak :selectable="true" :externalFilter="filterKontrak" @selected="onSelectedModalKontrak"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import SerahTerimaCustomerRepository from '@/repositories/marketing/serah-terima-customer-repository'
import ProgressDokumenUnitRepository from '@/repositories/proyek/progress-dokumen-unit-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import Kontrak from '@/views/pages/marketing/kontrak/Kontrak'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import moment from 'moment'
import { compressImage, convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'StcAdd',
  props: ['isActive'],
  components: {
    ValidationErrors,
    flatPickr,
    Kontrak
  },
  computed: {
    filterKontrak () {
      return { status_unit: 'AJB', id_stc: 'EMPTY' }
    },
    tglHabisGaransi () {
      const tglStc = this.data.tgl_stc
      const masaGaransi = this.data.masa_garansi
      if (tglStc === null || masaGaransi === null) return null
      return moment(tglStc, 'YYYY-MM-DD').add(masaGaransi, 'days').format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      isCompressingFoto: false,
      isLoading: false,
      errors: null,
      modalKontrak: {
        active: false
      },
      data: {
        tgl_stc: moment().format('YYYY-MM-DD'),
        masa_garansi: null,
        id_progress_dokumen_unit: null,
        np_ajb: null,
        tgl_ajb: null,
        foto: [],
        files: [],
        listAjb: []
      }
    }
  },
  methods: {
    onChangeNoAjb (id) {
      const item = _.find(this.data.listAjb, item => item.id === id)
      this.data.no_ajb = item.no_dokumen
      this.data.tgl_ajb = item.tgl_selesai
    },

    onSelectedModalKontrak (item) {
      this.data.id_kontrak = item.id
      this.data.no_kontrak = item.no_kontrak
      this.data.id_properti_unit = item.id_properti_unit
      this.data.no_unit = item.no_unit
      this.data.nama_customer = item.nama_customer
      this.data.masa_garansi = item.masa_garansi
      this.modalKontrak.active = false
      this.getListAjb()
    },

    async onSelectedPhotos (event) {
      const files = event.target.files
      const items = []
      this.isCompressingFoto = true
      for (const file of files) {
        const compressed = await compressImage(file)
        items.push(compressed)
      }
      this.isCompressingFoto = false
      this.data.foto = items
    },

    onSelectedFiles (event) {
      const files = event.target.files
      const items = []
      for (const file of files) {
        items.push(file)
      }
      this.data.files = items
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData({
        ...this.data,
        tgl_habis_garansi: this.tglHabisGaransi
      })
      SerahTerimaCustomerRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            if (errors.approver) {
              this.notifyErrorUsingDialog(errors)
            } else {
              this.errors = errors
            }
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getListAjb () {
      const filter = {
        id_properti_unit: this.data.id_properti_unit,
        is_ajb: 1
      }

      const params = { filter: JSON.stringify(filter) }
      ProgressDokumenUnitRepository.all(params)
        .then(response => {
          this.data.listAjb = response.data.data
          if (response.data.data.length > 0) {
            this.data.id_progress_dokumen_unit = response.data.data[0].id
            this.data.no_ajb = response.data.data[0].no_dokumen
            this.data.tgl_ajb = response.data.data[0].tgl_selesai
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
